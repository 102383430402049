<template>
  <div>

    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return{
      white: false,
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>